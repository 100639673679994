var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs',{staticStyle:{"border-bottom":"1px solid var(--v-foreground-5-base)"},attrs:{"hide-slider":"","active-class":"primary white--text","background-color":"transparent","height":"36"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(" "+_vm._s(_vm.$t('validations_create.create'))+" ")]),_c('v-tab',[_vm._v(_vm._s(_vm.$t('validations_create.duplicate'))+" ")])],1),_c('v-tabs-items',{staticClass:"transparent",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-card-text',{staticStyle:{"padding":"0px"}},[_c('MutationForm',{attrs:{"no-reset":"","mutation":"createValidation","constants":{ environmentId: _vm.environmentId },"submitButtonText":_vm.$t('validations_create.create'),"submitButtonIcon":"add","schema":{
            environmentId: {
              __graphQLType: 'ID'
            },
            name: {
              label: _vm.$t('validations_create.schema.name.label'),
              type: 'string',
              description: _vm.$t('validations_create.schema.name.description'),
              newDesign: true
            }
          }},on:{"success":function($event){return _vm.$emit('create', $event)},"error":function($event){return _vm.$emit('error', $event)}}},[_c('v-btn',{staticClass:"rounded-sm",staticStyle:{"border":"2px solid var(--v-primary-base)"},attrs:{"slot":"left-buttons","outlined":""},on:{"click":function($event){return _vm.$emit('dismiss')}},slot:"left-buttons"},[_vm._v(" "+_vm._s(_vm.$t('validations_create.cancelBtn'))+" ")])],1)],1)],1),_c('v-tab-item',[_c('v-card-text',{staticClass:"background-2 pa-0"},[_c('div',{staticClass:"pa-4"},[_c('div',{staticClass:"overline"},[_vm._v(" "+_vm._s(_vm.$t('validations_create.whatDuplicate1'))+" ")]),_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t('validations_create.whatDuplicate2'))+" ")]),_c('div',{staticClass:"overline"},[_vm._v(_vm._s(_vm.$t('validations_create.whatNoDuplicate1')))]),_c('div',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t('validations_create.whatNoDuplicate2')))]),_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t('validations_create.whatNoDuplicate3'))+" ")])]),_c('MutationForm',{attrs:{"no-reset":"","mutation":"duplicateValidation","environmentVariables":{ environmentId: _vm.environmentId },"submitButtonText":_vm.$t('validations_create.duplicateBtn'),"submitButtonIcon":"content_copy","schema":{
            validationId: {
              __graphQLType: 'ID',
              label: _vm.$t('validations_create.schema.validationId.label'),
              fieldType: 'componentSelect',
              fieldOptions: {
                componentTypeName: 'validations'
              },
              newDesign: true
            },
            newName: {
              label: _vm.$t('validations_create.schema.newName.label'),
              type: 'string',
              description: _vm.$t('validations_create.schema.newName.description'),
              newDesign: true
            }
          }},on:{"success":function($event){return _vm.$emit('create', $event)},"error":function($event){return _vm.$emit('error', $event)}}},[_c('v-btn',{staticClass:"rounded-sm",staticStyle:{"border":"2px solid var(--v-primary-base)"},attrs:{"slot":"left-buttons","outlined":""},on:{"click":function($event){return _vm.$emit('dismiss')}},slot:"left-buttons"},[_vm._v(_vm._s(_vm.$t('validations_create.cancelBtn'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }